
import { Component, Vue } from 'vue-property-decorator';
import DropdownTitle from '@/components/ui/dropdown-title';
import TeamGrid from '@/components/team/TeamGrid.vue';
import SelectorBar from '@/components/ui/selector-bar/SelectorBar.vue';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import FiltersMobile from '@/components/team/FiltersMobile.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import Tag from '@/components/ui/tag';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import { SelectorBarOption } from '@/components/ui/selector-bar';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
import { Role, Specialisations } from '@/typings/domain';
import RolesModule from '@/store/modules/roles/RolesModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import user from '@/api/user/user.api';
import UsersModule from '@/store/modules/users/UsersModule';
import SettingsModule from '@/store/modules/settings/SettingsModule';

@Component({
  name: 'Team',
  components: {
    TeamGrid,
    SelectorBar,
    Dropdown,
    Tag,
    DropdownTitle,
    ContextMenuLink,
    FiltersMobile,
    Icon,
  },
})
export default class Team extends Vue {
  $mq: string;
  public pageLoaded = false;
  public specialisations: Array<Specialisations> = []
  public selectedSort: Array<any> = [];
  public specSelectVisible = false;
  public sortSelectVisible = false;
  public selectorBarValue = 0;
  public page = 1;
  public settings = [] as any;
  public roles: Role[] = null;
  public selectedSpecializations: Array<any> = [];
  selectorBarOptions: Array<SelectorBarOption> = [
    { title: 'Все', count: TeamsModule.teams.length, value: -1 },
  ];
  mounted() 
  { 
    if(!Object.keys(this.owner).length)
    { 
      this.fetchOwner();
    }
    this.loadSettings().then(() => 
    {
      const payload = 
      {
        'sort[by]': `${this.settings.default_sorting}`,
        'sort[direction]': 'desc',
      }
      TeamsModule.fetchTeams(
      {
        page: this.page, 
        ...payload,
      })
    })
    this.setSpecialisations()
  }
  get owner()
  {
    return UsersModule.ownerUser;
  }
  async loadSettings() 
  {
    if(!SettingsModule.getSettings.length)
    {
      await SettingsModule.fetchSettings();
    }
    this.settings = this.sectionSettings.filter(el => el.slug === 'team')[0].settings;
  }
  get sectionSettings()
  {
      return SettingsModule.getSettings
  }
  updateSort(value: any)
  {
    this.selectedSort = value
  }
  deselectSpecialization(specialisation: string | number) {
    TeamsModule.clearTeams();
    this.specSelectVisible = false;
    this.selectorBarValue = -1;
    const index = this.selectedSpecializations.indexOf(specialisation as never);
    if (index !== -1) this.selectedSpecializations.splice(index, 1);
  }
  applySort(value: string)
  {    
    if(this.selectedSort.length && this.selectedSort[0].name === value)
    {
      this.selectedSort = []
      TeamsModule.clearTeams();
    }
    else
    {
      if(value === 'По оценке')
        this.selectedSort = [{name: value, sortType: 'rating'}]
      else if(value === 'По дате регистрации')
        this.selectedSort = [{name: value, sortType: 'created_at'}]
    }
    this.sortSelectVisible = true;
    this.specSelectVisible = false;
  }

  /*
  **  Выбор специализации и вызов
  **  фильтра для данной специализации
  */
  addToSelectedSpecialisation(value: any) 
  {
    if(value.value as number === -1)
    {
      TeamsModule.clearTeams();
      this.selectedSpecializations = [];
    }
    else
    {
      const specialisation = this.specialisations.filter((el) => el.id === value.value)[0]
      this.selectedSpecializations = [specialisation]
    }

    this.selectorBarValue = value.value
    this.sortSelectVisible = false;
    this.specSelectVisible = true;
  }
  deselectSort()
  {
    this.sortSelectVisible = false;
    this.selectedSort.pop()
  }
  async setSpecialisations()
  {
    await TeamsModule.fetchTeamsSpecialisations();
    this.selectorBarOptions = this.selectorBarOptions.slice(0,6)


    this.specialisations = TeamsModule.teamsSpecialisation;
    
    this.specialisations.forEach((element) => 
      {
        if(element.name.length > 20)
        {
          this.selectorBarOptions.push({title: element.name, value: element.id, count: element.users_count})
        }
        else
        {
          this.selectorBarOptions.push({title: element.name, value: element.id, count: element.users_count})
        }
      })
      this.selectorBarOptions = this.selectorBarOptions.slice(0,6)
      setTimeout(() =>
      {
        this.selectorBarOptions[0].count = TeamsModule.teams.length;
      }, 100);

      // this.selectorBarOptions[0].count = this.specialisations.reduce((acc, curr) => curr.users_count + acc, 0)
  }
  
  get isMobile() {
    return /(xs|sm)/.test(this.$mq);
  }
  onChange(selected: string[]) {
    this.selectedSpecializations = selected;
  }
  async fetchOwner()
  {
    await UsersModule.fetchOwner();
  }
  filterByRole(role: any) {
    SpecializationsModule.fetchSpecialisations({'filter[role]': role.value != -1 ? role.value : null}).finally(() => 
    {
      this.specialisations = SpecializationsModule.specialisations
    })
    // KnowledgeModule.fetchKnowledge({id: 1})
    // SpecializationsModule.fetchSpecialisations({'filter[role_id]': specialisation.role_id})
  }
}
