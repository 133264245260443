
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import * as CSS from 'csstype';
import FiltersMobile from '@/components/team/FiltersMobile.vue';
import DropdownTitle from '@/components/ui/dropdown-title';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import { SelectorBarOption, Range } from './model';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
// import { Role } from '@/typings/domain';
// import TeamGrid from '@/components/team/TeamGrid.vue';

@Component({
  name: 'SelectorBar',
  components:{
    DropdownTitle,
    FiltersMobile,
  },
})
export default class SelectorBar extends Vue {
  // @Prop() roles: Role[];
  @Prop() value: string | number;
  @Prop() specialisations: Array<object>;
  @Prop({default: false}) needMore: boolean;
  @Prop({ required: true }) options: Array<SelectorBarOption>;

  $refs!: {
    options: Array<HTMLElement>;
    selectorBar: HTMLElement;
  };
  selectorLoaded = false;
  innerValue: string | number = '';
  backplateStyles: CSS.Properties = {};
  previousSelectedIndex = 0;
  get specialisationsUserCount()
  {
    return this.specialisations.slice(6, this.specialisations.length).reduce((acc, curr: any) => curr.users_count + acc, 0)
  }
  beforeMount() {
    this.innerValue = this.value ? this.value : this.options[0].value;
  }

  get selectedOption() {
    return this.options.find(o => o.value === this.innerValue) ? this.options.find(o => o.value === this.innerValue) : this.options[0];
  }

  get selectedOptionIndex(): number {
    return this.options.indexOf(this.selectedOption);
  }

  @Emit('change')
  select(index: number) {
    this.previousSelectedIndex = this.selectedOptionIndex;
    this.innerValue = this.options[index].value;
    if(this.$route.fullPath.indexOf('documents/create') === 1 || this.$route.params.documentId)
    {
      return this.innerValue
    }
    else
    {
      return this.selectedOption;
    }
  }

  @Emit('moreChanged')
  moreSelected(index: number) {
    // eslint-disable-next-line
    // @ts-ignore
    this.$refs.dropdownTitle.$children[0].isExpanded = false
    return {title: 'valueFromMore', value: index};
  }
  @Watch('value')
  onValueChange() {
    this.innerValue = this.value;
  }
  
  @Watch('specialisations')
  onSpecsChange() 
  {
    if(!this.selectorLoaded)
    {
      this.selectorLoaded = true;
      this.calculateBackplateStyles();
    }
    else
    {
      this.calculateBackplateStyles();
    }
  }

  @Watch('$i18n.locale')
  @Watch('innerValue')
  calculateBackplateStyles() {
    if(this.innerValue != undefined)
    { 
      if(this.selectedOptionIndex === -1) 
        this.updateBackplatePosition([this.previousSelectedIndex, this.previousSelectedIndex]);  
      else
        this.updateBackplatePosition([this.previousSelectedIndex, this.selectedOptionIndex]);

      setTimeout(() => this.updateBackplatePosition(), 250);
    }
  }
  getShortName(item: string)
  {
    return item.length > 10 ? item.slice(0, 10) : item;
  }
  updateBackplatePosition(range?: Range) {
    let $secondOption: HTMLElement;
    let secondOptionBox: DOMRect;

    range?.sort();

    const currentOptionIndex: number = this.selectedOptionIndex;
    const $option: HTMLElement = this.$refs.options[range ? range[0] : currentOptionIndex];
    const $selectorBar: HTMLElement = this.$refs.selectorBar;
    const optionBox = $option.getBoundingClientRect();
    const selectorBarBox = $selectorBar.getBoundingClientRect();

    if (range) {
      $secondOption = this.$refs.options[range[1]];
      secondOptionBox = $secondOption.getBoundingClientRect();
    } else {
      $secondOption = $option;
      secondOptionBox = optionBox;
    }

    this.backplateStyles = {
      top: `${optionBox.top - selectorBarBox.top}px`,
      bottom: `${selectorBarBox.bottom - secondOptionBox.bottom}px`,
      left: `${optionBox.left - selectorBarBox.left}px`,
      right: `${selectorBarBox.right - secondOptionBox.right}px`,
      opacity: 1,
    };
  }

  mounted() {
    this.calculateBackplateStyles();
    this.innerValue = this.value != 0 ? this.value : this.options[0].value;
  }
}
